import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { IconButton, TextField } from '@material-ui/core';
import * as Icons from '@material-ui/icons';
import { Link as RouterLink } from 'react-router-dom';
import NeokodeButton from '../components/NeokodeButton';

const useStyles = makeStyles((theme) => ({
  addButton: {
    margin: '0',
  },
  rootSearch: {
    padding: theme.spacing(0.5, 0.5, 0),
    justifyContent: 'space-between',
    display: 'flex',
    alignItems: 'flex-start',
    flexWrap: 'wrap',
  },
  textFieldSearch: {
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
    margin: theme.spacing(1, 0.5, 1.5),
    '& .MuiSvgIcon-root': {
      marginRight: theme.spacing(0.5),
    },
    '& .MuiInput-underline:before': {
      borderBottom: `1px solid ${theme.palette.divider}`,
    },
  },
  refreshIcon: {
    color: '#666',
    margin: '0 15px'
  },
}));

function QuickSearchToolbar(props) {
  const classes = useStyles();

  return (
    <div className={classes.rootSearch}>
      <TextField
        variant="standard"
        value={props.value}
        onChange={props.onChange}
        placeholder="Buscar…"
        className={classes.textFieldSearch}
        InputProps={{
          startAdornment: <Icons.Search fontSize="small" />,
          endAdornment: (
            <IconButton
              title="Limpiar"
              aria-label="Limpiar"
              size="small"
              style={{ visibility: props.value ? 'visible' : 'hidden' }}
              onClick={props.clearSearch}
            >
              <Icons.Clear fontSize="small" />
            </IconButton>
          ),
        }}
      />
      <IconButton className={classes.refreshIcon} onClick={props.onRefresh}>
        <Icons.Refresh />
      </IconButton>
      <NeokodeButton
        variant="contained"
        className={classes.addButton}
        startIcon={<Icons.Add />}
        component={RouterLink}
        to={props.addButtonUrl}
      >
        {!!props.addButtonText ? props.addButtonText : 'Agregar'}
      </NeokodeButton>
    </div>
  );
}

QuickSearchToolbar.propTypes = {
  clearSearch: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
  onRefresh: PropTypes.func.isRequired,
  addButtonUrl: PropTypes.string.isRequired,
  addButtonText: PropTypes.string.isRequired
};

export default QuickSearchToolbar;