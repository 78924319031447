import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Typography, IconButton, Grid, Paper, TextField, Button,
  Dialog, DialogActions, DialogContent, DialogTitle,
  Snackbar,
  Tooltip,
  Chip,
} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import { DataGrid, esES } from '@material-ui/data-grid';
import * as Icons from '@material-ui/icons';
import { Link as RouterLink } from 'react-router-dom';
import NoRowsOverlay from '../components/NoRowsOverlay';
import QuickSearchToolbar from '../components/QuickSearchToolbar';
import moment from 'moment';
import { invokeApig } from '../libs/awsLib';
import { fixInstance, getPlanName, getState } from '../libs/instanceHelper';

const useStyles = makeStyles((theme) => ({
  section: {
    margin: theme.spacing(3, 0),
    marginBottom: theme.spacing(10),
    width: 'inherit',
  },
  paper: {
    padding: theme.spacing(2),
  },
  gridMaxMd: {
    [theme.breakpoints.up('md')]: {
      maxWidth: theme.breakpoints.values.lg,
    },
  },
  deleteButton: {
    backgroundColor: '#f44336',
    '&:hover': {
      backgroundColor: '#f44336',
    },
  },
  resetButton: {
    backgroundColor: '#2196f3',
    '&:hover': {
      backgroundColor: '#2196f3',
    },
  },
  textValidate: {
    margin: '15px 0',
  },
  configIcon: {
    color: '#383838',
  },
  editIcon: {
    color: '#2196f3',
  },
  lockIcon: {
    color: '#ff9800',
  },
  deleteIcon: {
    color: '#f44336',
  },
  buttonsDialogContainer: {
    padding: '10px 25px',
  },
}));

function escapeRegExp(value) {
  return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
}

function InstancesPage(props) {
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [message, setMessage] = useState('');
  const [openDialog, setOpenDialog] = useState(false);
  
  const [instanceId, setInstanceId] = useState('');
  const [isResetInstance, setIsResetInstance] = useState(false);
  const [isDeleteInstance, setIsDeleteInstance] = useState(false);
  const [textValidate, setTextValidate] = useState('');
  
  const [instances, setInstances] = useState([]);
  const [rows, setRows] = useState(instances);
  const [searchText, setSearchText] = useState('');
  const [openToast, setOpenToast] = useState(false);
  const [messageToast, setMessageToast] = useState('');

  useEffect(() => {
    getInstances();
  }, []);

  const getInstances = async () => {
    try {
      setIsLoading(true);
      setInstances([]);
      setRows([]);
      setSearchText('');
      const response = await invokeApig({
        path: "/instance/find",
        method: "POST",
      });
      if (response.status === 'OK') {
        const newInstances = response.instances.map((instance, index)=> { return fixInstance(instance); });
        setInstances(newInstances);
        setRows(newInstances);
      } else {
        setMessage('Error al obtener los sistemas de ERP. Por favor contactar a Soporte.');
        setIsError(true);
      }
    } catch (error) {
      console.error('Error in getAccess:', error);
      setMessage('Error al obtener los sistemas de ERP. Intenta nuevamente o consulta a Soporte.');
      setIsError(true);
    }
    setIsLoading(false);
  }

  const resetInstance = async (instanceId) => {
    try {
      setIsLoading(true);
      const response = await invokeApig({
        path: `/instance/reboot`,
        method: "POST",
        body: {
          id: instanceId
        }
      });
      if (response.Status !== 0) {
        setMessage('No se pudo reiniciar el ERP. Intenta nuevamente o contacta a Soporte');
        setIsError(true);
      } else {
        setMessageToast('Se ha enviado a reiniciar el ERP, esto puede tardar un tiempo. Consulta el estado del ERP');
        setOpenToast(true);
        setOpenDialog(false);
      }
    } catch (error) {
      setMessage('Ocurrió un error al reiniciar el ERP. Por favor intenta más tarde o comunícate con Soporte');
      setIsError(true);
    }
    setIsLoading(false);
  }

  const deleteInstance = async (instanceId) => {
    try {
      setIsLoading(true);
      const response = await invokeApig({
        path: `/instance/${instanceId}`,
        method: "DELETE"
      });
      if (response.Status !== 0) {
        setMessage('Error al eliminar el ERP');
        setIsError(true);
      } else {
        setMessageToast('Se ha eliminado el ERP correctamente');
        setOpenToast(true);
        setOpenDialog(false);
      }
    } catch (error) {
      console.error('Error in getAccess:', error);
      setMessage('Ocurrió un error al eliminar el ERP. Por favor intenta más tarde o comunícate con Soporte');
      setIsError(true);
    }
    setIsLoading(false);
  }

  const requestSearch = (searchValue) => {
    setSearchText(searchValue);
    const searchRegex = new RegExp(escapeRegExp(searchValue), 'i');
    const filteredRows = users.filter((row) => {
      return Object.keys(row).some((field) => {
        return searchRegex.test(row[field].toString());
      });
    });
    setRows(filteredRows);
  };

  const handleShowResetInstance = (instanceId) => {
    setIsError(false);
    setTextValidate('');
    setInstanceId(instanceId);
    setIsResetInstance(true);
    setIsDeleteInstance(false);
    setOpenDialog(true);
  };
  const handleShowDeleteInstance = (instanceId) => {
    setIsError(false);
    setTextValidate('');
    setInstanceId(instanceId);
    setIsResetInstance(false);
    setIsDeleteInstance(true);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  }

  const handleConfirmAction = () => {
    if (isResetInstance && textValidate === 'reiniciar ERP') {
      resetInstance(instanceId);
    }
    if (isDeleteInstance && textValidate === 'eliminar ERP') {
      deleteInstance(instanceId);
    }
  }

  const handleCloseToast = () => {
    setOpenToast(false);
  }

  const handleChange = (event) => {
    setTextValidate(event.target.value);
    setIsError(false);
  };

  const columns = [
    {
      field: 'LaunchTime',
      headerName: 'Fecha de lanzamiento',
      flex: 1,
      filterable: false ,
      renderCell: (params) => (
        moment(params.value).format("DD-MM-YYYY HH:mm")
      )
    },
    {
      field: 'TagsName',
      headerName: 'Nombre',
      flex: 1,
    },
    {
      field: 'TagsPlan',
      headerName: 'Plan',
      flex: 1,
      renderCell: (params) => (
        getPlanName(params.value)
      )
    },
    {
      field: 'TagsType',
      headerName: 'Tipo',
      flex: 1,
    },
    {
      field: 'TagsReplicaId',
      headerName: 'Copia BD',
      flex: 1,
      renderCell: (params) => (
        params.value ?
        <Chip label='Sí' icon={<Icons.Storage style={{color: '#fff'}}/>} style={{color: '#fff', backgroundColor: '#147efd'}} />
        :
        <Chip label='No' icon={<Icons.Storage style={{color: '#fff'}}/>} style={{color: '#fff', backgroundColor: '#6c757d'}} />
      )
    },
    {
      field: 'StateName',
      headerName: 'Estado',
      flex: 1,
      sortable: false,
      renderCell: (params) => {
        const state = getState(params.value);
        return (
          <Chip label={state.name} icon={<Icons.Check style={{color: '#fff'}}/>} style={{color: '#fff', backgroundColor: state.color}} />
        )
      }
    },
    {
      field: 'actions',
      headerName: 'Acciones',
      flex: 1,
      filterable: false ,
      renderCell: (params) => (
        (params.row.StateName === 'running') &&
        <>
          <Tooltip title="Configurar ERP" enterDelay={500} leaveDelay={200}>
            <IconButton component={RouterLink} to={`/instance/config/${params.id}`} className={classes.configIcon}>
              <Icons.Settings />
            </IconButton>
          </Tooltip>
          <Tooltip title="Editar ERP" enterDelay={500} leaveDelay={200}>
            <IconButton component={RouterLink} to={`/instance/${params.id}`} className={classes.editIcon}>
              <Icons.Edit />
            </IconButton>
          </Tooltip>
          {/*
            <Tooltip title="Reiniciar ERP" enterDelay={500} leaveDelay={200}>
              <IconButton onClick={() => handleShowResetInstance(params.id)} className={classes.lockIcon}>
                <Icons.Autorenew />
              </IconButton>
            </Tooltip>
            <Tooltip title="Elminar ERP" enterDelay={500} leaveDelay={200}>
              <IconButton onClick={() => handleShowDeleteInstance(params.id)} className={classes.deleteIcon}>
                <Icons.Delete />
              </IconButton>
            </Tooltip>
          */}
        </>
      ),
    }
  ];

  const showConfirmDialog = () => {
    return (
      <Dialog open={openDialog} onClose={handleCloseDialog} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Confirmación</DialogTitle>
        <DialogContent>
          <Alert severity="info">
            <p>{ isResetInstance && 'Se va a reiniciar el ERP, este proceso puede tardar unos minutos Esta acción se recomienda en caso que el servicio no esté disponible o experimente lentitud generalizada.' }</p>
            <p>{ isDeleteInstance && 'Se va a eliminar el ERP y ya no tendrá acceso. Esta acción no se puede reversar.' }</p>
            <p><b>¿Estás seguro que quieres continuar con la acción?</b></p>
          </Alert>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12}>
              <TextField
                label="Validación"
                helperText={
                  isDeleteInstance ?
                  "Para eliminar el ERP por favor ingresa el texto 'eliminar ERP'"
                  :
                  "Para reiniciar el ERP por favor ingresa el texto 'reiniciar ERP'"
                }
                name="textValidate"
                value={textValidate}
                onChange={handleChange}
                required
                fullWidth
                className={classes.textValidate}
              />
            </Grid>
          </Grid>
          {
            isError &&
            <Alert severity="error">
              {message}
            </Alert>
          }
        </DialogContent>
        <DialogActions className={classes.buttonsDialogContainer}>
          <Button
            type="button"
            variant="contained"
            className={classes.button}
            onClick={handleCloseDialog}
            disabled={isLoading}
          >
            No, cerra esta ventana
          </Button>
          <Button
            type="button"
            color={isDeleteInstance ? "secondary" : "primary"}
            variant="contained"
            className={isDeleteInstance ? classes.deleteButton : classes.resetButton}
            onClick={handleConfirmAction}
            disabled={isLoading || (isDeleteInstance && textValidate !== 'eliminar ERP') || (isResetInstance && textValidate !== 'reiniciar ERP')}
          >
            Sí, confirmo
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  return (
    <Grid container spacing={2} className={classes.section} justifyContent="center">
      <Grid item xs={12} sm={12} md={12} lg={12} className={classes.gridMaxMd}>
        <Paper className={classes.paper}>
          <Typography variant="h4" gutterBottom>
            Administración de ERP
          </Typography>

          <div style={{ height: '430px', width: '100%' }}>
            <DataGrid
              getRowId={(row) => row.InstanceId}
              rows={rows}
              columns={columns}
              pageSize={5}
              rowsPerPageOptions={[5]}
              loading={isLoading}
              components={{
                NoRowsOverlay: NoRowsOverlay,
                Toolbar: QuickSearchToolbar
              }}
              componentsProps={{
                toolbar: {
                  value: searchText,
                  onChange: (event) => requestSearch(event.target.value),
                  clearSearch: () => requestSearch(''),
                  onRefresh: () => getInstances(),
                  addButtonUrl: '/instance/create',
                  addButtonText: 'Contratar ERP'
                },
              }}
              localeText={esES.props.MuiDataGrid.localeText}
            />
          </div>
          { (isResetInstance || isDeleteInstance) && showConfirmDialog() }
        </Paper>
      </Grid>
      <Snackbar open={openToast} autoHideDuration={6000} onClose={handleCloseToast}>
        <Alert onClose={handleCloseToast} severity="success">
          {messageToast}
        </Alert>
      </Snackbar>
    </Grid>
  );
}

export default InstancesPage;
