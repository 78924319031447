import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import {
  Typography,
  Grid,
  Paper,
  CircularProgress,
} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import { Link as RouterLink } from 'react-router-dom';
import { invokeApig } from '../libs/awsLib';
import InstanceConfig from '../containers/InstanceConfig';
import NeokodeButton from '../components/NeokodeButton';
import { fixInstance } from '../libs/instanceHelper';

const useStyles = makeStyles((theme) => ({
  section: {
    margin: theme.spacing(3, 0),
    marginBottom: theme.spacing(10),
    width: 'inherit',
  },
  gridMaxWidth: {
    [theme.breakpoints.up('md')]: {
        maxWidth: theme.breakpoints.values.md,
    },
  },
  paper: {
    padding: theme.spacing(2),
  },
  
}));

function InstancePage(props) {
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const [message, setMessage] = useState('');
  const { id } = useParams();
  const [instance, setInstance] = useState(null);

  useEffect(() => {
    getInstance();
  }, []);

  const getInstance = async() => {
    if (id) {
        try {
            setIsLoading(true);
            const response = await invokeApig({
                path: `/instance/${id}`,
            });
            if (response.Status === 0) {
              const fixedInstance = fixInstance(response.instance);
              setInstance(fixedInstance);
            } else {
              setMessage('Ocurrió un error al obtener el detalle del ERP. Intanta nuevamente o comunícate con Soporte');
              setIsError(true);
            }
        } catch (error) {
          setMessage('Error al obtener los datos del ERP. Intanta nuevamente o comunícate con Soporte');
          setIsError(true)
        }
        setIsLoading(false);
    } else {
      setMessage('Error de acceso');
      setIsError(true)
      setIsLoading(false);
    }
  }

  const goInstanceList = () => {
    props.history.push('/instances');
  }

  const handleUpdateReplica = async() => {
    getInstance();
  }

  return (
    <Grid container spacing={2} className={classes.section} justifyContent="center">
      <Grid item xs={12} sm={12} md={12} lg={12} className={classes.gridMaxWidth}>
        <Paper className={classes.paper}>
          <Typography variant="h4" gutterBottom>
            Configuración de ERP
          </Typography>
          {
            isLoading ?
            <CircularProgress />
            :
            (
              isError ?
              <>
                <Alert severity="error">
                    {message}
                </Alert>
                <NeokodeButton
                  type="button"
                  variant="contained"
                  className={classes.button}
                  component={RouterLink}
                  to="/instances"
                >
                  Volver al listado de Sistemas ERP
                </NeokodeButton>
              </>
              :
              <InstanceConfig {...props} instance={instance} onFinish={goInstanceList} />
            )
          }
        </Paper>
      </Grid>
    </Grid>
  );
}

export default InstancePage;
