import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Card, CardContent, Typography, Avatar, Paper, CircularProgress } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import Alert from '@material-ui/lab/Alert';
import * as Icons from '@material-ui/icons';
import { Auth } from 'aws-amplify';
import NeokodeButton from '../components/NeokodeButton';
import { Link as RouterLink } from 'react-router-dom';
import { invokeApig } from '../libs/awsLib';

const useStyles = makeStyles((theme) => ({
	welcome: {
		marginTop: '15px',
	},
	userCard: {
		backgroundColor: '#383838',
		color: '#fff',
		textAlign: 'center',
	},
	avatar: {
		backgroundColor: '#00b31f',
		width: theme.spacing(7),
		height: theme.spacing(7),
		margin: 'auto'
	},
	avatarSkeleton: {
		margin: '0 auto'
	},
	section: {
		margin: theme.spacing(3, 0),
		marginBottom: theme.spacing(10),
		width: 'inherit',
	},
	paper: {
		padding: theme.spacing(2),
		textAlign: 'center',
	},
	gridMax: {
		[theme.breakpoints.up('md')]: {
			maxWidth: theme.breakpoints.values.lg,
		},
	},
	text: {
		marginBlockEnd: '16px',
		marginBlockStart: '16px',
	},
	instancesContainer: {
		margin: '0',
	},
	instancesTitle: {
		textAlign: 'center',
	},
	instancesCount: {
		margin: '15px',
	},
	instances: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'center',
	},
	button: {
		marginTop: theme.spacing(2),
	},
}));

function HomePage(props) {
	const { access, isAutenticate } = props.params;
	const classes = useStyles();
	const [userInfo, setUserInfo] = useState({});
	const [instancesCount, setInstancesCount] = useState(0);
	const [isLoadingUserInfo, setIsLoadingUserInfo] = useState(true);
	const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const [message, setMessage] = useState('');

	useEffect(() => {
		getUserInfo();
		getInstancesCount();
	}, []);

	const getUserInfo = async () => {
		try {
			const userData = await Auth.currentAuthenticatedUser();
			setUserInfo(userData);
		} catch (error) {
			console.error('Error al obtener los datos del usuario: ', error);
		}
		setIsLoadingUserInfo(false);
	}

	const getInstancesCount = async () => {
		try {
      setIsLoading(true);
			setMessage('');
			setIsError(false);
			const response = await invokeApig({
				path: "/instance/count",
				method: "GET",
			});
			if (response.status === 'OK') {
				setInstancesCount(response.instances);
			} else{
				setMessage('Ocurrió un error al obtener los sistemas de ERP. Intanta nuevamente o comunícate con Soporte');
        setIsError(true);
			}
    } catch (error) {
			console.log(error);
      setMessage('Error al obtener los sistemas de ERP. Intanta nuevamente o comunícate con Soporte');
      setIsError(true)
    }
		setIsLoading(false);
	}

	return (
		<Grid container spacing={2} className={classes.section} justifyContent="center">
			<Grid item xs={12} sm={12} md={12} lg={12} className={classes.gridMax}>
				<Grid container spacing={2}>

					<Grid item xs={12} sm={12} md={12} lg={12}>
						<Card className={classes.userCard}>
							<CardContent>
								{
									isLoadingUserInfo ?
										<div>
											<Skeleton variant="circle" width={56} height={56} className={classes.avatarSkeleton}/>
											<Skeleton variant="text" height={47} />
											<Skeleton variant="text" height={32} />
										</div>
										:
										(
											(userInfo && userInfo.attributes) &&
											<>
												<Avatar className={classes.avatar}>
													<Icons.AccountCircle />
												</Avatar>
												<Typography variant="h5" className={classes.welcome}>Bienvenido, <b>{userInfo.attributes.name} {userInfo.attributes.family_name}</b></Typography>
												<Typography variant="h6">{userInfo.attributes.email}</Typography>
											</>
										)
								}
							</CardContent>
						</Card>
					</Grid>

					<Grid item xs={12} sm={12} md={8} lg={8} className={classes.section2}>
						<Card className={classes.userCard2}>
							<CardContent>
								<Typography variant="h4" className={classes.welcome}><b>ERP App</b></Typography>
								<Typography className={classes.text}>
									Gestiona los procesos de tu empresa: ventas, compras, bodega, recursos humanos, producción y más para tener una visión integral de toda la empresa.
								</Typography>
								<Typography className={classes.text}>
									Con un ERP, la información en tu organización fluye libremente, las decisiones se toman con datos actualizados y la empresa se mueve al unísono hacia sus objetivos.
								</Typography>
								<Typography className={classes.text}>
									Los <b>beneficios</b> de un ERP son:
								</Typography>
								<ul>
									<li><b>Visión 360°</b>: Obtenga una vista panorámica de su negocio, con información en tiempo real que le permite tomar decisiones informadas.</li>
									<li><b>Eficiencia mejorada</b>: Automatice tareas repetitivas y concentre su energía en la estrategia y el crecimiento.</li>
									<li><b>Colaboración aumentada</b>: Fomente la colaboración entre departamentos, eliminando silos y mejorando la comunicación.</li>
									<li><b>Informes avanzados</b>: Genere informes detallados y análisis profundos para entender mejor su negocio.</li>
								</ul>
								<Typography className={classes.text}>
									En el mundo empresarial de hoy, estar conectado no es sólo una ventaja, es una necesidad. Un ERP no sólo simplifica la gestión interna, sino que también prepara su empresa para enfrentar los desafíos del futuro, adaptándose rápidamente a los cambios del mercado y manteniéndose a la vanguardia de la innovación.
								</Typography>
							</CardContent>
						</Card>
					</Grid>

					<Grid item xs={12} sm={12} md={4} lg={4} className={classes.gridMax}>
						<Paper className={classes.paper}>
							<Typography variant="h4" className={classes.instancesTitle}>ERP</Typography>
							<Typography variant="subtitle1" gutterBottom>
								Acá podrás administrar tu ERP
							</Typography>
							<Grid container justifyContent="center" className={classes.instancesContainer}>
								{
									isLoading ?
										<Grid item xs={12} sm={12} md={12} lg={12} >
											<Skeleton variant="text" height={78} />
											<Skeleton variant="text" height={36} />
										</Grid>
										:
										(
											isError ?
											<Alert severity="error">
												{message}
											</Alert>
											:
											<div className={classes.instances}>
												<Alert severity="info" className={classes.instancesCount}>
													Sistemas ERP contratados: <b>{instancesCount}</b>
												</Alert>
												<NeokodeButton
													variant="contained"
													component={RouterLink}
													to="/instances" 
												>
													Ver/Contratar ERP
												</NeokodeButton>
											</div>
										)
								}
							</Grid>
						</Paper>
					</Grid>

				</Grid>
			</Grid>
		</Grid>
	);
}
export default HomePage;