import React, { Fragment, useEffect, useState } from 'react';
import {
  Button, Typography, Grid, CircularProgress, TextField, Chip,
  Dialog, DialogActions, DialogContent, DialogTitle,
  Snackbar,
  ButtonGroup,
} from '@material-ui/core';
import * as Icons from '@material-ui/icons';
import NeokodeButton from '../components/NeokodeButton';
import { Alert, AlertTitle } from '@material-ui/lab';
import { makeStyles } from '@material-ui/core/styles';
import { darken } from '@material-ui/core/styles/colorManipulator';
import moment from 'moment';
import { invokeApig } from '../libs/awsLib';
import { formatMonetary } from "../libs/formatter";
import { getPlanName, getState, salesMap } from "../libs/instanceHelper";
import config from '../config';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(4),
  },
  attribute: {
    marginBottom: theme.spacing(1),
    fontWeight: 'bold',
  },
  value: {
    marginBottom: theme.spacing(1),
  },
  stateIndicator: {
    fontWeight: 'bold',
    color: (props) => (props.estado === 'disponible' ? 'green' : 'red'),
  },
  buttonContainer: {
    marginTop: '30px',
  },
  buttonChangePlan: {
    backgroundColor: '#2196f3',
    color: '#fff',
    '&:hover': {
      backgroundColor: darken('#2196f3', 0.2),
      color: '#fff',
    },
  },
  buttonReboot: {
    backgroundColor: '#ff9800',
    color: '#fff',
    '&:hover': {
      backgroundColor: darken('#ff9800', 0.2),
      color: '#fff',
    },
  },
  buttonDelete: {
    backgroundColor: '#f44336',
    color: '#fff',
    '&:hover': {
      backgroundColor: darken('#f44336', 0.2),
      color: '#fff',
    },
  },
  buttonReplica: {
    backgroundColor: '#4caf50',
    color: '#fff',
    '&:hover': {
      backgroundColor: darken('#4caf50', 0.2),
      color: '#fff',
    },
  },
  buttonsDialogContainer: {
    padding: '24px',
  },
  changePlanTitle: {
    marginBottom: '15px',
  },
  changePlanButtons: {
    marginBottom: '15px',
  },
  buttonSales: {
    textTransform: 'none',
    justifyContent: 'left',
  },
  currentPlan: {
    marginBottom: '15px',
  }
}));

function InstanceDetail(props) {
  const classes = useStyles();
  const instance = props.instance;
  const instanceId = props.instance.InstanceId;

  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [message, setMessage] = useState('');
  const [openDialog, setOpenDialog] = useState(false);
  const [isResetInstance, setIsResetInstance] = useState(false);
  const [isDeleteInstance, setIsDeleteInstance] = useState(false);
  const [isChangePlan, setIsChangePlan] = useState(false);
  const [isCreateReplica, setIsCreateReplica] = useState(false);
  const [textValidate, setTextValidate] = useState('');
  const [sales, setSales] = useState(0);
  const [uf, setUf] = useState(0);
  const [openToast, setOpenToast] = useState(false);
  const [messageToast, setMessageToast] = useState('');

  const getUf = async () => {
    try {
      const response = await invokeApig({
        app: 'billing',
        path: `/billing/uf`
      });
      if (response.code === 0) {
        setUf(response.payload);
      } else {
        console.error('Error UF:', response);  
      }
    } catch (error) {
      console.error('Error UF:', error);
    }
  };

  const resetInstance = async () => {
    try {
      setIsLoading(true);
      const response = await invokeApig({
        path: `/instance/reboot`,
        method: "POST",
        body: {
          id: instanceId
        }
      });
      if (response.Status !== 0) {
        setMessage('No se pudo reiniciar el ERP. Intenta nuevamente o contacta a Soporte');
        setIsError(true);
      } else {
        setMessageToast('Se ha enviado a reiniciar el ERP, esto puede tardar un tiempo. Consulta el estado del ERP');
        setOpenToast(true);
        setOpenDialog(false);
      }
    } catch (error) {
      setMessage('Ocurrió un error al reiniciar el ERP. Por favor intenta más tarde o comunícate con Soporte');
      setIsError(true);
    }
    setIsLoading(false);
  }

  const changePlan = async () => {
    try {
      setIsLoading(true);
      if (!sales) {
        setMessage('Debes seleccionar el nivel de ventas anuales para modificar el plan.');
        setIsError(true);
        setIsLoading(false);
        return;
      }
      const response = await invokeApig({
        path: `/instance/plan`,
        method: "PUT",
        body: {
          id: instanceId,
          sales: sales
        }
      });
      if (response.Status !== 0) {
        setMessage('No se pudo modificar el plan. Intenta nuevamente o contacta a Soporte');
        setIsError(true);
      } else {
        setMessageToast('Se ha generado la solicitud de modificación del plan, esto puede tardar unos minutos. Consulta el estado en el listado de Sistemas ERP');
        setOpenToast(true);
        setOpenDialog(false);
      }
    } catch (error) {
      setMessage('Ocurrió un error al cambiar el plan. Por favor intenta más tarde o comunícate con Soporte');
      setIsError(true);
    }
    setIsLoading(false);
  }

  const createReplica = async () => {
    try {
      setIsLoading(true);
      const response = await invokeApig({
        path: `/instance/database`,
        method: "POST",
        body: {
          id: instanceId,
        }
      });
      if (response.Status !== 0) {
        setMessage('No se pudo crear la copia de base de datos. Intenta nuevamente o contacta a Soporte');
        setIsError(true);
      } else {
        setMessageToast('Se ha enviado a crear la copia de base de datos exitosamente, esto puede tardar unos minutos. Consulta el estado del ERP');
        setOpenToast(true);
        setOpenDialog(false);
      }
    } catch (error) {
      setMessage('Ocurrió un error al crear la copia de base de datos. Por favor intenta más tarde o comunícate con Soporte');
      setIsError(true);
    }
    setIsLoading(false);
  }

  const deleteInstance = async () => {
    try {
      setIsLoading(true);
      const response = await invokeApig({
        path: `/instance/${instanceId}`,
        method: "DELETE"
      });
      if (response.Status !== 0) {
        setMessage('Error al eliminar el ERP');
        setIsError(true);
      } else {
        setMessageToast('Se ha eliminado el ERP correctamente');
        setOpenToast(true);
        setOpenDialog(false);
      }
    } catch (error) {
      console.error('Error in getAccess:', error);
      setMessage('Ocurrió un error al eliminar el ERP. Por favor intenta más tarde o comunícate con Soporte');
      setIsError(true);
    }
    setIsLoading(false);
  }

  const handleShowResetInstance = () => {
    setIsError(false);
    setTextValidate('');
    setIsResetInstance(true);
    setIsChangePlan(false);
    setIsCreateReplica(false);
    setIsDeleteInstance(false);
    setOpenDialog(true);
  };
  const handleShowChangePlan = () => {
    getUf();
    setSales(0);
    setIsError(false);
    setTextValidate('');
    setIsResetInstance(false);
    setIsChangePlan(true);
    setIsCreateReplica(false);
    setIsDeleteInstance(false);
    setOpenDialog(true);
  }
  const handleShowCreateReplica = () => {
    setIsError(false);
    setTextValidate('');
    setIsResetInstance(false);
    setIsChangePlan(false);
    setIsCreateReplica(true);
    setIsDeleteInstance(false);
    setOpenDialog(true);
  }
  const handleShowDeleteInstance = () => {
    setIsError(false);
    setTextValidate('');
    setIsResetInstance(false);
    setIsChangePlan(false);
    setIsCreateReplica(false);
    setIsDeleteInstance(true);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  }

  const handleConfirmAction = () => {
    if (isResetInstance && textValidate === 'reiniciar ERP') {
      resetInstance();
    }
    if (isDeleteInstance && textValidate === 'eliminar ERP') {
      deleteInstance();
    }
    if (isChangePlan && textValidate === 'confirmo') {
      changePlan();
    }
    if (isCreateReplica && textValidate === 'confirmo') {
      createReplica();
    }
  }

  const handleChange = (event) => {
    setTextValidate(event.target.value);
    setIsError(false);
  };

  const handleCloseToast = () => {
    setOpenToast(false);
  }

  const showConfirmDialog = (dialogMessage, helperText, buttonClass, expectedTextValidate, dialogBody) => {
    return (
      <Dialog open={openDialog} onClose={handleCloseDialog} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Confirmación</DialogTitle>
        <DialogContent>
          { dialogBody && dialogBody() }
          <Alert severity="info">
            { dialogMessage }
            <p><b>¿Estás seguro que quieres continuar con la acción?</b></p>
          </Alert>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12}>
              <TextField
                label="Validación"
                helperText={helperText}
                name="textValidate"
                value={textValidate}
                onChange={handleChange}
                required
                fullWidth
                className={classes.textValidate}
              />
            </Grid>
          </Grid>
          {
            isError &&
            <Alert severity="error">
              {message}
            </Alert>
          }
        </DialogContent>
        <DialogActions className={classes.buttonsDialogContainer}>
          <Button
            type="button"
            variant="contained"
            className={classes.button}
            onClick={handleCloseDialog}
            disabled={isLoading}
          >
            No, cerra esta ventana
          </Button>
          <Button
            type="button"
            variant="contained"
            className={buttonClass}
            onClick={handleConfirmAction}
            disabled={isLoading || textValidate !== expectedTextValidate}
          >
            Sí, confirmo
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  const renderChangePlan = () => (
    <>
      <Typography component="h4" className={classes.changePlanTitle}>
        Para modificar el plan debes actualizar tus ventas anuales
      </Typography>
      <ButtonGroup size="small" orientation="vertical" aria-label="small outlined button group" className={classes.changePlanButtons}>
        {
          salesMap.map((saleMap, index) => {
            return (
              sales === saleMap.id ?
              <NeokodeButton key={'sale-active-' + index} className={classes.buttonSales} onClick={() => setSales(saleMap.id)}>
                <b>{saleMap.plan}</b>: {saleMap.desc}
                {uf > 0 && <>&nbsp;<small>(Aprox. {saleMap.max === -1 ? 'desde': 'hasta'} {formatMonetary({value: uf * saleMap.max})})</small></>}
              </NeokodeButton>
              :
              <Button key={'sale-' + index} className={classes.buttonSales} onClick={() => setSales(saleMap.id)}>
                <b>{saleMap.plan}</b>: {saleMap.desc}
                {uf > 0 && <>&nbsp;<small>(Aprox. {saleMap.max === -1 ? 'desde': 'hasta'} {formatMonetary({value: uf * saleMap.max})})</small></>}
              </Button>
            )
          })
        }
      </ButtonGroup>
      <Typography component="h6" className={classes.currentPlan}>
        Plan actual: <b>{getPlanName(instance.TagsPlan)}</b>
      </Typography>
    </>
  );

  const renderLabelAndField = (label, field, isLink) => (
    <>
      <Grid item xs={4} md={2}>
        <Typography variant="inherit" className={classes.attribute}>
          {label}
        </Typography>
      </Grid>
      <Grid item xs={8} md={4}>
        <Typography variant="inherit" className={classes.value}>
          {
            (isLink && field.startsWith('http')) ?
            <a href={field} target='_blank'>{field}</a>
            :
            field
          }
        </Typography>
      </Grid>
    </>
  );

  const renderState = (state) => (
    <Chip label={state.name} icon={<Icons.Check style={{color: '#fff'}}/>} style={{color: '#fff', backgroundColor: state.color, height: 'inherit'}} />
  )

  const getUrl = (domain) => {
    return !!domain ? `https://${domain.replace(/.$/, '')}` : '';
  }

  return (
    <div className={classes.root}>
      <Grid container spacing={2}>
        {renderLabelAndField('Nombre', instance.TagsName, false)}
        {renderLabelAndField('Plan', getPlanName(instance.TagsPlan), false)}
        {renderLabelAndField('Tipo', instance.TagsType, false)}
        {renderLabelAndField('Estado', renderState(getState(instance.StateName)), false)}
        {renderLabelAndField('Fecha contratación', moment(instance.LaunchTime).format("DD-MM-YYYY HH:mm"), false)}
        {renderLabelAndField('Plataforma', instance.PlatformDetails, false)}
        {renderLabelAndField('URL', getUrl(instance.TagsErpDomain), true)}
      </Grid>
      { isResetInstance && showConfirmDialog(
        <p>Se va a reiniciar el ERP, este proceso puede tardar unos minutos Esta acción se recomienda en caso que el servicio no esté disponible o experimente lentitud generalizada.</p>,
        "Para confirmar el reinicio del ERP por favor ingresa el texto 'reiniciar ERP'",
        classes.buttonReboot,
        'reiniciar ERP',
        undefined
      )}
      { isDeleteInstance && showConfirmDialog(
        <p>Se va a eliminar el ERP y ya no tendrá acceso. Esta acción no se puede reversar.</p>,
        "Para confirmar la eliminación del ERP por favor ingresa el texto 'eliminar ERP'",
        classes.buttonDelete,
        'eliminar ERP',
        undefined
      )}
      { isChangePlan && showConfirmDialog(
        <p>El cambio de plan tiene por objetivo ajustarse a la realidad de la empresa. Es por esto que recomendamos utilizar esta funcionalidad con cuidado ya que tiene directa relación con el desempeño del sistema y las condiciones comerciales.</p>,
        "Para confirmar el cambio de plan por favor ingresa el texto 'confirmo'",
        classes.buttonChangePlan,
        'confirmo',
        renderChangePlan
      )}
      { isCreateReplica && showConfirmDialog(
        <>
          <p>Estás a punto de crear una copia de base de datos de sólo lectura.</p>
          <p>Esta copia se crea para utilizar software de inteligencia empresarial como Power BI, Tableau, Amazon QuickSight o similares.</p>
          <p>Ten en consideración que el ERP podría experimentar lentitud mientras se realiza este proceso. Te recomendamos realizar esto en ventanas de tiempo de poco uso del sistema.</p>
          <Alert severity='warning'>
            Este servicio tiene costo que puedes ver en <a href="https://www.neokode.cl/services/erp#pricing" target='_blank'>Precios ERP</a>
          </Alert>
        </>,
        "Para confirmar la creación de la copia de base de datos por favor ingresa el texto 'confirmo'",
        classes.buttonReplica,
        'confirmo',
        undefined
      )}
      <Grid container justifyContent="flex-end" spacing={2} className={classes.buttonContainer}>
        <Grid item>
          <Button
            type="button"
            variant="contained"
            className={classes.buttonReboot}
            onClick={handleShowResetInstance}
            startIcon={<Icons.Autorenew />}
          >
            Reiniciar
          </Button>
        </Grid>
        {/**
        <Grid item>
          <Button
            type="button"
            variant="contained"
            className={classes.buttonChangePlan}
            onClick={handleShowChangePlan}
            startIcon={<Icons.Edit />}
          >
            Cambiar plan
          </Button>
        </Grid>
        */}
        {
          !instance.TagsReplicaId &&
          <Grid item>
            <Button
              type="button"
              variant="contained"
              className={classes.buttonReplica}
              onClick={handleShowCreateReplica}
              startIcon={<Icons.Storage />}
            >
              Crear Copia de BD
            </Button>
          </Grid>
        }
        <Grid item>
          <Button
            type="button"
            variant="contained"
            className={classes.buttonDelete}
            onClick={handleShowDeleteInstance}
            startIcon={<Icons.Delete />}
          >
            Dar de baja
          </Button>
        </Grid>
      </Grid>
      <Snackbar open={openToast} autoHideDuration={6000} onClose={handleCloseToast}>
        <Alert onClose={handleCloseToast} severity="success">
          {messageToast}
        </Alert>
      </Snackbar>
    </div>
  );
}

export default InstanceDetail;