import React, { Fragment, useEffect, useState } from 'react';
import {
  Button, Typography, Grid, CircularProgress, TextField, Chip,
  Dialog, DialogActions, DialogContent, DialogTitle,
  Snackbar,
  ButtonGroup,
  Paper,
} from '@material-ui/core';
import * as Icons from '@material-ui/icons';
import NeokodeButton from '../components/NeokodeButton';
import { Alert, AlertTitle } from '@material-ui/lab';
import { makeStyles } from '@material-ui/core/styles';
import { darken } from '@material-ui/core/styles/colorManipulator';
import moment from 'moment';
import { invokeApig } from '../libs/awsLib';
import { formatMonetary } from "../libs/formatter";
import { getPlanName, getState, salesMap } from "../libs/instanceHelper";
import config from '../config';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(4),
  },
  attribute: {
    marginBottom: theme.spacing(1),
    fontWeight: 'bold',
  },
  value: {
    marginBottom: theme.spacing(1),
  },
  stateIndicator: {
    fontWeight: 'bold',
    color: (props) => (props.estado === 'disponible' ? 'green' : 'red'),
  },
  buttonContainer: {
    marginTop: '30px',
  },
  buttonEditAdmin: {
    backgroundColor: '#2196f3',
    color: '#fff',
    '&:hover': {
      backgroundColor: darken('#2196f3', 0.2),
      color: '#fff',
    },
  },
  buttonReboot: {
    backgroundColor: '#ff9800',
    color: '#fff',
    '&:hover': {
      backgroundColor: darken('#ff9800', 0.2),
      color: '#fff',
    },
  },
  buttonDelete: {
    backgroundColor: '#f44336',
    color: '#fff',
    '&:hover': {
      backgroundColor: darken('#f44336', 0.2),
      color: '#fff',
    },
  },
  buttonReplica: {
    backgroundColor: '#4caf50',
    color: '#fff',
    '&:hover': {
      backgroundColor: darken('#4caf50', 0.2),
      color: '#fff',
    },
  },
  buttonsDialogContainer: {
    padding: '24px',
  },
  changePlanTitle: {
    marginBottom: '15px',
  },
  changePlanButtons: {
    marginBottom: '15px',
  },
  buttonSales: {
    textTransform: 'none',
    justifyContent: 'left',
  },
  currentPlan: {
    marginBottom: '15px',
  }
}));

function InstanceConfig(props) {
  const classes = useStyles();
  const instanceId = props.instance.InstanceId;
  const instance = props.instance;

  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [message, setMessage] = useState('');

  const [adminUser, setAdminUser] = useState({
    name: '',
    email: '',
    password: '',
  });
  const [openEditAdminDialog, setOpenEditAdminDialog] = useState(false);
  
  const [openToast, setOpenToast] = useState(false);
  const [messageToast, setMessageToast] = useState('');


  /**
  invoicing: getInvoicing(instance.invoicing, instance.invoicingTicket, instance.invoicingGuide)      
   */

  const handleEditAdmin = async () => {
    try {
      setIsLoading(true);
      setIsError(false);
      const request = {
        ...adminUser,
        id: instanceId,
      }
      const response = await invokeApig({
        path: "/instance/admin",
        method: "POST",
        body: request
      });
      if (response.Status === 0) {
        setMessageToast('Se ha creado el administrador correctamente');
        setOpenToast(true);
        handleCloseEditAdminDialog();
      } else {
        setMessage('No hemos podido crear/editar el administrador, por favor intenta más tarde o contacta a Soporte');
        setIsError(true);
      }
    } catch(error) {
      setMessage('Error al crear/editar el administrador, por favor intenta más tarde o contacta a Soporte');
      setIsError(true);
    }
    setIsLoading(false);
  }

  const handleChangeChecked = (event) => {
    const { name, checked } = event.target;
    //setInstance({ ...instance, [name]: checked });
    setIsError(false);
  };

  const handleChangeAdmin = (event) => {
    const { name, value } = event.target;
    setAdminUser({ ...adminUser, [name]: value });
    setIsError(false);
  };

  const handleCloseToast = () => {
    setOpenToast(false);
  }

  const handleCloseEditAdminDialog = () => {
    setOpenEditAdminDialog(false);
  }

  const handleShowEditAdminDialog = () => {
    setAdminUser({
      name: '',
      email: '',
      password: '',
    });
    setOpenEditAdminDialog(true);
  }

  const renderEditAdminForm = () => {
    return (
      <>
        <Typography component="h4" className={classes.formTitle}>
          Para crear o editar un administrador por favor completa los siguientes datos
        </Typography>
        <form noValidate autoComplete="off">
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12}>
              <TextField label="Nombre" name="name" value={adminUser.name} onChange={handleChangeAdmin} required fullWidth className={classes.formControl} autoComplete='new-password' />
            </Grid>
            <Grid item xs={12} sm={12}>
              <TextField label="Email" name="email" value={adminUser.email} onChange={handleChangeAdmin} required fullWidth className={classes.formControl} autoComplete='new-password' />
            </Grid>
            <Grid item xs={12} sm={12}>
              <TextField label="Contraseña" type="password" name="password" value={adminUser.password} onChange={handleChangeAdmin} required fullWidth className={classes.formControl} autoComplete='new-password' />
            </Grid>
          </Grid>
        </form>
      </>
    );
  }

  const getStepElectronicInvoice = () => {
    let WithInvoicingButton = instance.invoicing === 1 ? NeokodeButton : Button;
    let WithoutInvoicingButton = instance.invoicing === 2 ? NeokodeButton : Button;
    return (
      <Paper className={classes.paper}>
        <Typography component="h4" className={classes.stepTitle}>
          Facturación electrónica
        </Typography>
        <ButtonGroup size="small" aria-label="small outlined button group">
          <WithInvoicingButton onClick={() => handleChange({ target: { name: 'invoicing', value: 1 } })}>
            Sí, quiero facturar desde el ERP
          </WithInvoicingButton>
          <WithoutInvoicingButton onClick={() => handleChange({ target: { name: 'invoicing', value: 2 } })}>
            No por ahora, quiero facturar por mi cuenta
          </WithoutInvoicingButton>
        </ButtonGroup>
        {
          (instance.invoicing === 1) &&
          <FormGroup row>
            <FormControlLabel
              control={<Switch checked={true} className={classes.invocingCheck} />}
              label="Factura, NC y ND"
            />
            <FormControlLabel
              control={<Switch checked={instance.invoicingTicket} className={classes.invocingCheck} onChange={handleChangeChecked} name="invoicingTicket" />}
              label="Boleta electrónica"
            />
            <FormControlLabel
              control={<Switch checked={instance.invoicingGuide} className={classes.invocingCheck} onChange={handleChangeChecked} name="invoicingGuide" />}
              label="Guía de despacho"
            />
          </FormGroup>
        }
      </Paper>
    );
  }


  const getStepConfirm = () => {
    return (
      <Paper className={classes.paper}>
        <Typography component="h4" className={classes.stepTitle}>
          Por favor confirma los datos antes de contratar el ERP
        </Typography>
        <Grid container >
          <Grid item md={4}><b>RUT</b></Grid>
          <Grid item md={8}>{instance.rut}</Grid>
        </Grid>
        <Divider variant="fullWidth" />
        <Grid container >
          <Grid item md={4}><b>Razón social</b></Grid>
          <Grid item md={8}>{instance.name}</Grid>
        </Grid>
        <Divider variant="fullWidth" />
        <Grid container >
          <Grid item md={4}><b>Facturación electrónica</b></Grid>
          <Grid item md={8}>{
            instance.invoicing === 1 ?
              (
                <>
                  Factura electrónica<br />
                  Nota de crédito<br />
                  Nota de débito<br />
                  {instance.invoicingTicket && <>Boleta electrónica<br /></>}
                  {instance.invoicingGuide && <>Guía de despacho<br /></>}
                </>
              )
              :
              'No por ahora, quiero facturar por mi cuenta'
          }
          </Grid>
        </Grid>
      </Paper>
    );
  }

  /**}
          if (!instance.rut) {
            setMessage('Debes ingresar el RUT de la empresa');
            isValid = false;
          } else
          if (!instance.name) {
            setMessage('Debes ingresar la razón social de la empresa');
            isValid = false;
          }
          if (instance.invoicing !== 1 && instance.invoicing !== 2) {
            setMessage('Debes responder sobre la faturación');
            isValid = false;
          }
  
  
  { renderStep({ label: 'Datos tributarios de la empresa', body: getStepCompany(), }) }
  { renderStep({ label: 'Facturación electrónica', body: getStepElectronicInvoice(), }) }
  
  */

  const renderEditAdminDialog = () => {
    return (
      <Dialog open={openEditAdminDialog} onClose={handleCloseEditAdminDialog} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Crear/Editar administrador</DialogTitle>
        <DialogContent>
          {renderEditAdminForm()}
          {
            isError &&
            <Alert severity="error">
              {message}
            </Alert>
          }
        </DialogContent>
        <DialogActions className={classes.buttonsDialogContainer}>
          <Button
            type="button"
            variant="contained"
            className={classes.button}
            onClick={handleCloseEditAdminDialog}
            disabled={isLoading}
          >
            Cerrar ventana
          </Button>
          <Button
            type="button"
            variant="contained"
            className={classes.buttonEditAdmin}
            onClick={handleEditAdmin}
            disabled={isLoading}
          >
            Crear/Editar administrador
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  const renderLabelAndField = (label, field, isLink) => (
    <>
      <Grid item xs={4} md={2}>
        <Typography variant="inherit" className={classes.attribute}>
          {label}
        </Typography>
      </Grid>
      <Grid item xs={8} md={4}>
        <Typography variant="inherit" className={classes.value}>
          {
            (isLink && field.startsWith('http')) ?
              <a href={field} target='_blank'>{field}</a>
              :
              field
          }
        </Typography>
      </Grid>
    </>
  );

  const renderState = (state) => (
    <Chip label={state.name} icon={<Icons.Check style={{ color: '#fff' }} />} style={{ color: '#fff', backgroundColor: state.color, height: 'inherit' }} />
  )

  const getUrl = (domain) => {
    return !!domain ? `https://${domain.replace(/.$/, '')}` : '';
  }

  return (
    <div className={classes.root}>
      <Grid container spacing={2}>
        {renderLabelAndField('Nombre', instance.TagsName, false)}
        {renderLabelAndField('Tipo', instance.TagsType, false)}
        {renderLabelAndField('Estado', renderState(getState(instance.StateName)), false)}
        {renderLabelAndField('URL', getUrl(instance.TagsErpDomain), true)}
      </Grid>

      <Grid container justifyContent="flex-end" spacing={2} className={classes.buttonContainer}>
        <Grid item>
          <Button
            type="button"
            variant="contained"
            className={classes.buttonEditAdmin}
            onClick={handleShowEditAdminDialog}
            startIcon={<Icons.Person />}
          >
            Crear/editar administrador
          </Button>
        </Grid>
      </Grid>
      <Snackbar open={openToast} autoHideDuration={6000} onClose={handleCloseToast}>
        <Alert onClose={handleCloseToast} severity="success">
          {messageToast}
        </Alert>
      </Snackbar>
      {renderEditAdminDialog()}
    </div>
  );
}

export default InstanceConfig;