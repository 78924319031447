export function fixInstance(instance) {
  const array = instance.Tags;
  for (const pair in array) {
    instance['Tags' + array[pair].Key] = array[pair].Value;
  }
  delete instance.Tags;
  instance.StateCode = instance.State.Code;
  instance.StateName = instance.State.Name;
  delete instance.State;
  return instance;
}

export function getState(name) {
  const state = {};
  switch(name) {
    case 'stopping':
      state.color = '#dc3545';
      state.name = 'Deteniéndose';
      break;
    case 'stopped':
      state.color = '#fd7e14';
      state.name = 'Detenida';
      break;
    case 'running':
      state.color = '#00b31f';
      state.name = 'Disponible';
      break;
    case 'pending':
      state.color = '#ffc107';
      state.name = 'Pendiente';
      break;
    case 'shutting-down':
      state.color = '#6c757d';
      state.name = 'Dando de baja';
      break;
    case 'terminated':
      state.color = '#343a40';
      state.name = 'Dado de baja';
      break;
    default:
      state.color = '#adb5bd';
      state.name = 'Desconocido'
  }
  return state;
}

export function getReplicaStatus(domain, status) {
  const state = {};
  switch(status) {
    case 'create':
      if (domain === '-') {
        state.name = 'Creando';
        state.color = '#ffc107';
      } else {
        state.name = 'Creada y lista para actualizar';
        state.color = '#ffc107';
      }
      break;
    case 'doing':
      state.name = 'Actualizando datos';
      state.color = '#dc3545';
      break;
    case 'done':
      state.name = 'Actualizada';
      state.color = '#00b31f';
      break;
    case 'error':
      state.name = 'Error';
      state.color = '#343a40';
      break;
    default:
      state.name = 'Desconocido'
      state.color = '#adb5bd';
  }
  return state;
}

export function getPlanName(plan) {
  let plan_name = '';
  switch(plan) {
    case 'basic':
      plan_name = 'Emprende';
      break;
    case 'pyme':
      plan_name = 'PYME';
      break;
    case 'standard':
      plan_name = 'Estándar';
      break;
    case 'premium':
      plan_name = 'Premium';
      break;
    default:
      plan_name = 'Desconocido'
  }
  return plan_name;
}

export function getPlan(sales) {
  var plan = '';
  switch(sales) {
    case 1:
      plan = 'basic';
      break;
    case 2:
      plan = 'pyme';
      break;
    case 3:
      plan = 'standard';
      break;
    case 4:
      plan = 'premium';
      break;
    default:
      plan = 'basic';
  }
  return plan;
}

export function getInvoicing(invoicing, ticket, guide) {
  const res = [];
  if (invoicing === 1) {
    res.append(1);
    ticket && res.append(2);
    guide && res.append(3);
  }
  return res;
}

export const salesMap = [
  { id: 1, plan: 'Emprende', desc: 'Menos de 600 UF', max: 600},
  { id: 2, plan: 'PYME', desc: 'Entre 600 y 5.000 UF', max: 5000},
  { id: 3, plan: 'Estándar', desc: 'Entre 5.000 y 25.000 UF', max: 25000},
  { id: 4, plan: 'Premium', desc: 'PREMIUM: Más de 25.000 UF', max: -1}
];
