import React, { Fragment, useEffect, useState } from 'react';
import {
  Stepper, Step, StepLabel, StepContent, Button, Paper, FormControl,
  InputLabel, Select, Typography, Grid, CircularProgress,
  MenuItem, TextField,
  IconButton,
  ButtonGroup,
  Icon,
  FormGroup,
  FormControlLabel,
  Switch,
  Divider,
} from '@material-ui/core';
import * as Icons from '@material-ui/icons';
import NeokodeButton from '../components/NeokodeButton';
import { Alert, AlertTitle } from '@material-ui/lab';
import { makeStyles } from '@material-ui/core/styles';
import { invokeApig } from '../libs/awsLib';
import { formatMonetary } from "../libs/formatter";
import { getPlan, getInvoicing, salesMap } from "../libs/instanceHelper";
import config from '../config';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  paper: {
    padding: theme.spacing(2),
  },
  stepTitle: {
    marginBottom: '30px',
  },
  stepIcon: {
    '&.MuiStepIcon-active': {
      color: '#00b31f',
    },
    '&.MuiStepIcon-completed': {
      color: '#00b31f',
    },
  },
  text: {
    margin: 'revert',
  },
  button: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  actionsContainer: {
    marginBottom: theme.spacing(2),
  },
  resetContainer: {
    padding: theme.spacing(3),
  },
  formControl: {
    minWidth: 120,
    marginTop: '15px',
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  loadingDomains: {
    verticalAlign: 'bottom',
  },
  refreshDomainsButton: {
    paddingBottom: '5px',
    verticalAlign: 'bottom',
  },
  buttonSales: {
    display: 'block',
  },
  invocingCheck: {
    '& .Mui-checked': {
      color: '#00b31f',
      '& + .MuiSwitch-track': {
        backgroundColor: '#00b31f', // Color del track cuando está activado
      },
    },
  },
  checked: {},
  track: {},
}));

function InstanceForm(props) {
  const classes = useStyles();
  const [activeStep, setActiveStep] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [message, setMessage] = useState('');
  const [domains, setDomains] = useState([]);
  const [uf, setUf] = useState(0);
  const [isLoadingDomains, setIsLoadingDomains] = useState(true);
  const [isErrorDomains, setIsErrorDomains] = useState(false);
  const [instance, setInstance] = useState({
    domain: '',
    sales: 0,
  });

  useEffect(() => {
    getUf();
  }, []);

  const getUf = async () => {
    try {
      const response = await invokeApig({
        app: 'billing',
        path: `/billing/uf`
      });
      if (response.code === 0) {
        setUf(response.payload);
      } else {
        console.error('Error UF:', response);  
      }
    } catch (error) {
      console.error('Error UF:', error);
    }
  };

  const findDomains = async() => {
    try {
      setIsLoadingDomains(true);
      setIsErrorDomains(false);
      setDomains([]);
      const response = await invokeApig({
        app: "domain",
        path: "/domain/find",
        method: "POST",
      });
      if (response.status === 'OK') {
        setDomains(response.result);
      } else {
        setIsErrorDomains(true);
      }
    } catch (error) {
      console.error('Error:', error);
      setIsErrorDomains(true);
    }
    setIsLoadingDomains(false);
  }

  const handleChange = (event) => {
    const { name, value } = event.target;
    setInstance({ ...instance, [name]: value });
    setIsError(false);
  };

  const createInstance = async () => {
    let isSuccess = false;
    try {
      setIsLoading(true);
      setIsError(false);
      const request = {
        domain: instance.domain,
        plan: getPlan(instance.sales),
      }
      const response = await invokeApig({
        path: "/instance",
        method: "POST",
        body: request
      });
      if (response.Status === 0) {
        isSuccess = true;
      } else {
        setMessage('No hemos podido crear el ERP, por favor intenta más tarde o contacta a Soporte');
        setIsError(true);
      }
    } catch (error) {
      setMessage('Ha ocurrido un error al crear el ERP, por favor intenta más tarde o contacta a Soporte');
      setIsError(true);
    }
    setIsLoading(false);
    return isSuccess;
  };

  const handleGoBack = (event) => {
    event.preventDefault();
    if (props.onFinish) {
      props.onFinish(false);
    } else {
      props.history.push('/instances');
    }
  }

  const handleNext = async () => {
    let isValid = true;
    switch(activeStep) {
      case 0: {
        findDomains();
        break;
      }
      case 1: {
        if (!instance.domain) {
          setMessage('Debes seleccionar un dominio para continuar');
          isValid = false;
        }
        break;
      }
      case 2: {
        if (!instance.sales) {
          setMessage('Debes seleccionar un nivel de ventas anuales');
          isValid = false;
        }
        break;
      }
      case 3: {
        if (!await createInstance()) {
          isValid = false;
        }
      }
    }
    if (isValid) {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
    setIsError(!isValid);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const getStepDescription = () => {
    return (
      <Alert severity="info">
        <AlertTitle>¿Estás listo para llevar tu empresa al siguiente nivel?</AlertTitle>
        <Typography className={classes.text}>
          Con nuestro ERP, puedes optimizar tus operaciones, mejorar la toma de decisiones e impulsar el crecimiento sostenible.
        </Typography>
        <Typography className={classes.text}>
          En el siguiente asistente te guiaremos para configurar tu ERP y comenzar a utilizarlo ahora.
        </Typography>
        <Typography className={classes.text}>
          Te pediremos información para la configuración inicial, este proceso tomará sólo algunos minutos. Luego configuraremos tu sistema y te entregaremos los datos de acceso para que comiences a utilizarlo.
        </Typography>
        <Typography className={classes.text}>
          Comencemos!
        </Typography>
      </Alert>
    );
  }

  const getStepDomain = () => {
    return (
      <Paper className={classes.paper}>
        <Typography component="h4" className={classes.stepTitle}>
          Selecciona un dominio para configurar tu ERP
        </Typography>
        <Alert severity='info'>
          <p>Necestamos un dominio para que puedas acceder al ERP. Si no tienes un dominio configurado con nosotros te invitamos a hacerlo antes de seguir con el proceso.</p>
          <p>Si ya tienes un dominio debes configurarlo y si no tienes un dominio puedes contratarlo en <a href={config.apps.domain} target='_blank'>{config.apps.domain} <Icons.Launch /></a>.</p>
        </Alert>
        <FormControl className={classes.formControl}>
          <InputLabel id="domain-label">Dominio</InputLabel>
          <Select
            labelId="domain-label"
            id="domain"
            name="domain"
            value={domains.length > 0 ? instance.domain : ''}
            onChange={handleChange}
            disabled={isLoadingDomains}
          >
            {
              domains.map((domain, index) => (
                <MenuItem key={index} value={domain.name}>
                  {domain.name}
                </MenuItem>
              ))
            }
          </Select>
        </FormControl>
        {
          isLoadingDomains ?
          <CircularProgress className={classes.loadingDomains} />
          :
          <IconButton onClick={findDomains} className={classes.refreshDomainsButton}>
            <Icons.Refresh />
          </IconButton>
        }
        {
          isErrorDomains &&
          <Alert severity='error'>
            Ocurrió un error al obtener los dominios disponibles. Por favor intenta más tarde o comunícate con Soporte.
          </Alert>
        }
      </Paper>
    );
  }

  const getStepSales = () => {
    return (
      <Paper className={classes.paper}>
        <Typography component="h4" className={classes.stepTitle}>
          Ventas anuales
        </Typography>
        <ButtonGroup size="small" aria-label="small outlined button group">
          {
            salesMap.map((saleMap, index) => {
              return (
                instance.sales === saleMap.id ?
                <NeokodeButton key={'sale-active-' + index} className={classes.buttonSales} onClick={() => handleChange({target: {name: 'sales', value: saleMap.id}})}>
                  {saleMap.desc}
                  {uf > 0 && <small><br/>(Aprox. {saleMap.max === -1 ? 'desde': 'hasta'} {formatMonetary({value: uf * saleMap.max})})</small>}
                </NeokodeButton>
                :
                <Button key={'sale-' + index} className={classes.buttonSales} onClick={() => handleChange({target: {name: 'sales', value: saleMap.id}})}>
                  {saleMap.desc}
                  {uf > 0 && <small><br/>(Aprox. {saleMap.max === -1 ? 'desde': 'hasta'} {formatMonetary({value: uf * saleMap.max})})</small>}
                </Button>
              )
            })
          }
        </ButtonGroup>
      </Paper>
    );
  }

  const getStepConfirm = () => {
    return (
      <Paper className={classes.paper}>
        <Typography component="h4" className={classes.stepTitle}>
          Por favor confirma los datos antes de contratar el ERP
        </Typography>
        <Grid container >
          <Grid item xs><b>Dominio</b></Grid>
          <Grid item md={8}>{instance.domain}</Grid>
        </Grid>
        <Divider variant="fullWidth" />
        <Grid container >
          <Grid item md={4}><b>Ventas anuales</b></Grid>
          <Grid item md={8}>{
            salesMap.map((saleMap, index) => {
              return (instance.sales === saleMap.id) &&
                <Fragment key={index}>
                  {saleMap.desc}
                  {uf > 0 && <small><br/>(Aprox. {saleMap.max === -1 ? 'desde': 'hasta'} {formatMonetary({value: uf * saleMap.max})})</small>}
                </Fragment>
            })
          }</Grid>
        </Grid>
      </Paper>
    );
  }

  const getStepVoucher = () => {
    return (
      <Paper className={classes.paper}>
        <Typography component="h4" className={classes.stepTitle}>
          ERP contratado exitosamente.
        </Typography>
        <Alert severity="success">
          <p><b>¡Felicitaciones! Haz finalizado el proceso de contratación de tu ERP.</b></p>
          <p>
            Te damos la bienvenida a la familia de usuarios que utilizamos esta gran herramienta de gestión.
            Estás a punto de embarcarte en un viaje que transformará la forma en que tu negocio opera y crece.
          </p>
          <div>
            <b>Siguientes pasos</b>:<br/>
            <ul>
              <li>Revisa el estado de la configuración inicial en esta misma pantalla. La configuración inicial puede tardar unos minutos.</li>  
              <li>Revisa tu correo. Te notificaremos cuando finalice el proceso y te enviaremos los datos de acceso.</li>
              <li>Revisa los manuales y videos disponibles para comenzar a utilizarlo.</li>
              <li>Si lo consideras necesario coordina una capacitación.</li>
              <li>Comienza a utilizar tu ERP.</li>
            </ul>
          </div>
          <p>Estamos disponibles para apoyarte en lo que necesites.</p>
        </Alert>
      </Paper>
    );
  }

  const renderStep = ({label, body}) => (
    <Step>
      <StepLabel StepIconProps={{ classes: { root: classes.stepIcon } }}>{label}</StepLabel>
      <StepContent>
        { body }
        {
          isError &&
          <Alert severity="error">
            { message }
          </Alert>
        }
        <div className={classes.actionsContainer}>
            {
              activeStep > 3 ?
              <NeokodeButton
                variant="contained"
                onClick={handleGoBack}
                className={classes.button}
              >
                Ir a la lista de Sistemas ERP
              </NeokodeButton>
              :
              <div>
                <Button
                  disabled={activeStep === 0}
                  onClick={handleBack}
                  className={classes.button}
                >
                  Atrás
                </Button>
                <NeokodeButton
                  variant="contained"
                  onClick={handleNext}
                  className={classes.button}
                  disabled={isLoading}
                >
                  {activeStep === 3 ? (isLoading ? <CircularProgress /> : 'Contratar') : 'Siguiente'}
                </NeokodeButton>
              </div>
            }
        </div>
      </StepContent>
    </Step>
  );

  return (
    <div className={classes.root}>
      <Stepper activeStep={activeStep} orientation="vertical">
        { renderStep({ label: 'Descripción del servicio', body: getStepDescription(), }) }
        { renderStep({ label: 'Dominio', body: getStepDomain(), }) }
        { renderStep({ label: 'Ventas de la empresa', body: getStepSales(), }) }
        { renderStep({ label: 'Confirmación', body: getStepConfirm(), }) }
        { renderStep({ label: 'Comprobante', body: getStepVoucher(), }) }
      </Stepper>
    </div>
  );
}

export default InstanceForm;