import React, { Fragment, useEffect, useState } from 'react';
import {
  Button, Typography, Grid, CircularProgress, TextField, Chip,
  Dialog, DialogActions, DialogContent, DialogTitle,
  Snackbar,
  ButtonGroup,
  IconButton,
} from '@material-ui/core';
import * as Icons from '@material-ui/icons';
import NeokodeButton from '../components/NeokodeButton';
import { Alert, AlertTitle } from '@material-ui/lab';
import { makeStyles } from '@material-ui/core/styles';
import { darken } from '@material-ui/core/styles/colorManipulator';
import moment from 'moment';
import { invokeApig } from '../libs/awsLib';
import { formatMonetary } from "../libs/formatter";
import { getPlanName, getReplicaStatus, salesMap } from "../libs/instanceHelper";
import config from '../config';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(4),
  },
  attribute: {
    marginBottom: theme.spacing(1),
    fontWeight: 'bold',
  },
  value: {
    marginBottom: theme.spacing(1),
  },
  stateIndicator: {
    fontWeight: 'bold',
    color: (props) => (props.estado === 'disponible' ? 'green' : 'red'),
  },
  buttonContainer: {
    marginTop: '30px',
  },
  buttonUpdate: {
    backgroundColor: '#ff9800',
    color: '#fff',
    '&:hover': {
      backgroundColor: darken('#ff9800', 0.2),
      color: '#fff',
    },
  },
  buttonDelete: {
    backgroundColor: '#f44336',
    color: '#fff',
    '&:hover': {
      backgroundColor: darken('#f44336', 0.2),
      color: '#fff',
    },
  },
  buttonsDialogContainer: {
    padding: '24px',
  },
  showPasswordButton: {
    margin: '0 15px',
    padding: 0,
  }
}));

function ReplicaDetail(props) {
  const classes = useStyles();
  const instance = props.instance;
  const instanceId = props.instance.InstanceId;

  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [message, setMessage] = useState('');
  const [openDialog, setOpenDialog] = useState(false);
  const [isUpdateReplica, setIsUpdateReplica] = useState(false);
  const [isDeleteReplica, setIsDeleteReplica] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [textValidate, setTextValidate] = useState('');
  const [openToast, setOpenToast] = useState(false);
  const [messageToast, setMessageToast] = useState('');

  const updateReplica = async () => {
    try {
      setIsLoading(true);
      const response = await invokeApig({
        path: `/instance/database`,
        method: 'PUT',
        body: {
          id: instanceId
        }
      });
      if (response.Status !== 0) {
        setMessage('No se pudo actualizar los datos de la réplica. Intenta nuevamente o contacta a Soporte');
        setIsError(true);
      } else {
        setMessageToast('Se ha enviado a actualizar la réplica, esto puede tardar un tiempo. Consulta el estado de la réplica');
        setOpenToast(true);
        setOpenDialog(false);
      }
    } catch (error) {
      setMessage('Ocurrió un error al actualizar los datos de la réplica. Por favor intenta más tarde o comunícate con Soporte');
      setIsError(true);
    }
    setIsLoading(false);
  }

  const deleteReplica = async () => {
    try {
      setIsLoading(true);
      const response = await invokeApig({
        path: `/instance/database/${instanceId}`,
        method: 'DELETE'
      });
      if (response.Status !== 0) {
        setMessage('No se pudo eliminar la réplica. Intenta nuevamente o contacta a Soporte');
        setIsError(true);
      } else {
        setMessageToast('Se ha generado la solicitud de eliminación de la réplica, esto puede tardar unos minutos. Consulta el estado en el detalle del ERP');
        setOpenToast(true);
        setOpenDialog(false);
        props.onFinish && props.onFinish();
      }
    } catch (error) {
      console.log(error);
      setMessage('Ocurrió un error al eliminar la réplica. Por favor intenta más tarde o comunícate con Soporte');
      setIsError(true);
    }
    setIsLoading(false);
  }

  const handleShowUpdateReplica = () => {
    setIsError(false);
    setTextValidate('');
    setIsUpdateReplica(true);
    setIsDeleteReplica(false);
    setOpenDialog(true);
  };
  const handleShowDeleteReplica = () => {
    setIsError(false);
    setTextValidate('');
    setIsUpdateReplica(false);
    setIsDeleteReplica(true);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  }

  const handleConfirmAction = () => {
    if (isUpdateReplica && textValidate === 'confirmo') {
      updateReplica();
    }
    if (isDeleteReplica && textValidate === 'eliminar') {
      deleteReplica();
    }
  }

  const handleChange = (event) => {
    setTextValidate(event.target.value);
    setIsError(false);
  };

  const handleCloseToast = () => {
    setOpenToast(false);
  }

  const handleShowPassword = () => {
    setShowPassword(!showPassword);
  }

  const showConfirmDialog = (dialogMessage, helperText, buttonClass, expectedTextValidate, dialogBody) => {
    return (
      <Dialog open={openDialog} onClose={handleCloseDialog} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Confirmación</DialogTitle>
        <DialogContent>
          { dialogBody && dialogBody() }
          <Alert severity="info">
            { dialogMessage }
            <p><b>¿Estás seguro que quieres continuar con la acción?</b></p>
          </Alert>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12}>
              <TextField
                label="Validación"
                helperText={helperText}
                name="textValidate"
                value={textValidate}
                onChange={handleChange}
                required
                fullWidth
                className={classes.textValidate}
              />
            </Grid>
          </Grid>
          {
            isError &&
            <Alert severity="error">
              {message}
            </Alert>
          }
        </DialogContent>
        <DialogActions className={classes.buttonsDialogContainer}>
          <Button
            type="button"
            variant="contained"
            className={classes.button}
            onClick={handleCloseDialog}
            disabled={isLoading}
          >
            No, cerra esta ventana
          </Button>
          <Button
            type="button"
            variant="contained"
            className={buttonClass}
            onClick={handleConfirmAction}
            disabled={isLoading || textValidate !== expectedTextValidate}
          >
            Sí, confirmo
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  const renderLabelAndField = (label, field) => (
    <>
      <Grid item xs={4} md={2}>
        <Typography variant="inherit" className={classes.attribute}>
          {label}
        </Typography>
      </Grid>
      <Grid item xs={8} md={4}>
        <Typography variant="inherit" className={classes.value}>
          {field}
        </Typography>
      </Grid>
    </>
  );

  const renderState = (state) => (
    <Chip label={state.name} icon={<Icons.Check style={{color: '#fff'}}/>} style={{color: '#fff', backgroundColor: state.color, height: 'inherit'}} />
  );

  const renderPasswordLabel = (password, show, handle) => (
    <>
      {show ? password : '********' }
      <IconButton
        aria-label="toggle password visibility"
        onClick={handle}
        onMouseDown={(event) => event.preventDefault()}
        className={classes.showPasswordButton}
      >
        {show ? <Icons.Visibility /> : <Icons.VisibilityOff />}
      </IconButton>
    </>
  );

  return (
    <div className={classes.root}>
      <Grid container spacing={2}>
        {renderLabelAndField('Dominio', instance.TagsReplicaDomain)}
        {renderLabelAndField('Puerto', instance.TagsReplicaPort)}
        {renderLabelAndField('Usuario', instance.TagsReplicaUser)}
        {renderLabelAndField('Base de datos', instance.TagsReplicaDB)}
        {renderLabelAndField('Contraseña', renderPasswordLabel(instance.TagsReplicaPassword, showPassword, handleShowPassword))}
        {renderLabelAndField('Estado', renderState(getReplicaStatus(instance.TagsReplicaDomain, instance.TagsReplicaStatus)))}
      </Grid>
      { isUpdateReplica && showConfirmDialog(
        <>
          <p>Estás a punto de <b>actualizar los datos de la réplica de base de datos</b> de sólo lectura.</p>
          <p>Ten en consideración que el ERP podría experimentar lentitud mientras se realiza este proceso. Te recomendamos realizar esta actualización en ventanas de tiempo de poco uso del sistema.</p>
        </>,
        "Para confirmar la actualización de la réplica de base de datos por favor ingresa el texto 'confirmo'",
        classes.buttonUpdate,
        'confirmo',
        undefined
      )}
      { isDeleteReplica && showConfirmDialog(
        <>
          <p><b>ATENCION</b>: estás a punto de <b>eliminar la réplica de base de datos</b> de sólo lectura.</p>
          <p>Si eliminas la réplica no podrás acceder a los datos desde aplicaciones externas como Power BI. Puedes crear otra réplica a futuro si lo deseas.</p>
        </>,
        "Para confirmar la eliminación de la réplica de base de datos por favor ingresa el texto 'eliminar'",
        classes.buttonDelete,
        'eliminar',
        undefined
      )}
      <Grid container justifyContent="flex-end" spacing={2} className={classes.buttonContainer}>
        <Grid item>
          <Button
            type="button"
            variant="contained"
            className={classes.buttonUpdate}
            onClick={handleShowUpdateReplica}
            startIcon={<Icons.Autorenew />}
            disabled={isLoading}
          >
            Actualizar réplica
          </Button>
        </Grid>
        <Grid item>
          <Button
            type="button"
            variant="contained"
            className={classes.buttonDelete}
            onClick={handleShowDeleteReplica}
            startIcon={<Icons.Delete />}
            disabled={isLoading}
          >
            Eliminar réplica
          </Button>
        </Grid>
      </Grid>
      <Snackbar open={openToast} autoHideDuration={6000} onClose={handleCloseToast}>
        <Alert onClose={handleCloseToast} severity="success">
          {messageToast}
        </Alert>
      </Snackbar>
    </div>
  );
}

export default ReplicaDetail;